
<template>
  <div class="mt-4">
    <v-alert 
      :color="enrolled_status_map[course.enrolled_status.toString()].color" 
      :type="enrolled_status_map[course.enrolled_status.toString()].type"
      outlined 
      border="left" 
      class="py-2" 
      transition="scale-transition"
      prominent
      dense
      
    >

      <v-row align="center" no-gutters >
        <v-col cols="12" sm="12">
          <span class="text-subtitle-2">{{enrolled_status_map[course.enrolled_status.toString()].title}}</span>
        </v-col>
        <v-col cols="12" sm="3" class="d-flex pt-2" v-if="course.enrolled_status == 1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                text
                v-bind="attrs"
                v-on="on"
                class="mx-2"
                color="primary darken-1" 
                dark
                small
                v-if="['student'].includes(user.role.name) && course.status == 'done_selection'" 
                @click="submitConfirmEnrollment('confirmed')"
              ><v-icon small> mdi-thumb-up</v-icon></v-btn>
            </template>
            <span>Confirmar inscrição</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                v-bind="attrs"
                v-on="on"
                class="mx-2"
                dark
                color="red darken-1" 
                small
                text
                v-if="['student'].includes(user.role.name) && course.status == 'done_selection'" 
                @click="submitConfirmEnrollment('canceled')"
              ><v-icon small> mdi-thumb-down</v-icon></v-btn>
            </template>
            <span>Cancelar inscrição.</span>
          </v-tooltip>
        </v-col>
      </v-row>
      
    </v-alert>
    <v-row>
      <v-col cols="12" sm="3" class="pa-1">
        <div class="ml-2">
          <v-btn dark small @click="openDialog">Orientações de inscrição</v-btn>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" :fullscreen="!$vuetify.breakpoint.smAndUp" max-width="900">
      <v-card>
        <v-card-title primary-title class='primary white--text py-3' >
          CENTRO LATINO-AMERICANO DE BIOTECNOLOGIA
        </v-card-title>
        <v-card-text :style="responsivity_vh">
          <div class="text-h6">
            CALENDÁRIO DE CURSOS {{$dayjs(course.start_date).format('YYYY')}}
          </div>
          <div class="text-button">
            PROCESSO DE SELEÇÃO DE CANDIDATOS:
          </div>
          <div>
            A seleção de candidatos brasileiros e candidatos de países da América Latina para participarem de cursos realizados no Brasil será realizada pelos coordenadores dos cursos e homologada pela Escola Latino-Americana de Biotecnologia.
          </div>
          <div>
            A seleção de candidatos brasileiros para participarem de cursos realizados na Argentina, Colômbia, Paraguai ou Uruguai será feita pela Escola Latino-Americana de Biotecnologia.
          </div>
          <div>
            A seleção de candidatos argentinos, colombianos, paraguaios, uruguaios é de responsabilidade das respectivas escolas CABBIO ou pontos focais.
          </div>
          <div>
            Os seguintes critérios serão considerados durante a seleção de candidatos: estrita obediência ao calendário de inscrições; atendimento aos requisitos específicos para cada curso (cabbio.com.br); formação básica e específica; atuação profissional; grupo de pesquisa a que está vinculado. O processo de seleção ainda observa a distribuição regional e institucional dos candidatos, com o objetivo de comtemplar o máximo de regiões/instituições brasileiras, considerando o calendário de cursos como um todo.
          </div>
          <div class="text-button">
            BENEFÍCIOS E RESPONSABILIDADES
          </div>
          <div class="text-button">
            SELECIONADOS PARA CURSOS BRASILEIROS
          </div>
          <div>
            Os brasileiros selecionados, não residentes na cidade-sede do curso, receberão ajuda de custo do coordenador do curso brasileiro para o deslocamento (e.g. passagens aéreas) e estadia durante o período do curso. Ressaltamos que trata-se de um auxílio oferecido ao aluno, com a finalidade de contribuir com as despesas pessoais durante o período de aulas, não caractarizando cobertura total de todos os gastos correspondentes a sua estadia. 
          </div>
          <div>
            Atenção: o preenchimento dos formulários de avaliação dos cursos e a apresentação dos comprovantes de embarques (ida e volta de sua viagem) são imprescindíveis para o recebimento do certificado de participação no curso pelos brasileiros selecionados.
          </div>
          <div class="text-button">
            SELECIONADOS PARA CURSOS ARGENTINOS, COLOMBIANOS, PARAGUAIOS OU URUGUAIOS
          </div>
          <div>
          </div>
            Os brasileiros selecionados receberão ajuda de custo do coordenador do curso brasileiro para estadia durante o período do curso. Ressaltamos esse auxílio oferecido tem como finalidade contribuir com as despesas pessoais durante o período de aulas, não caractarizando cobertura total de todos os gastos correspondentes a sua estadia.
          <div>
          </div>
            A estadia proporcionada será em quartos duplos ou triplos, sendo a aquisição de passagens aéreas responsabilidade do país de origem do selecionado.
          <div>
          </div>
            A aquisição das passagens aéreas dos brasileiros selecionados é responsabilidade do CABBIO BRASIL. Os critérios para as compras das passagens estão fundamentados nos conceitos públicos de economicidade e razoabilidade.
          <div>
          </div>
            Os bilhetes serão adquiridos de acordo com a disponibilidade de cada companhia aérea, apresentando logística própria, incluindo horas adicionais de conexão nos aeroportos e/ou com troca de aeroporto na cidade de destino.
          <div>
          </div>
            Os brasileiros selecionados serão responsáveis pelos eventuais custos envolvidos no deslocamento para atender a logística das passagens aéreas. 
          <div>
          </div>
            O preenchimento das avaliações dos cursos e a apresentação dos comprovantes de embarques (ida e volta de sua viagem) são imprescindíveis para o recebimento do certificado de participação no curso.
          <div class="text-button">
            EMISSÃO DE CERTIFICADOS
          </div>
          <div>
            É obrigatório o preenchimento das avaliações dos cursos e a apresentação dos comprovantes de embarques (ida e volta) após o término do curso para emissão dos certificados de participação.
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn class="red" dark @click="dialog =! dialog">FECHAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'

export default {
  name: 'EnrollmentConfirmTip',
  props: {
    course: {
      type: Object,
      default: null
    },
    course_id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      enrolled_status_map: {
        '1': {title: 'Confirmação de inscrição pendente.', color: 'primary', type: 'warning' },
        '10': {title: 'Inscrição confirmada.' , color: 'success', type: 'success'},
        '20': {title: 'Inscrição cancelada.', color: 'orange', type: 'info'},
        '30' : {title: 'Inscrição não confirmada a tempo.', color: 'error', type: 'error'}
      },
      dialog: false,
      confirmed: false,
    }
  },
  methods: {
    openDialog(){
      this.dialog = true
      this.confirmed = true
    },
    submitConfirmEnrollment(status){
      let msg_title = this.confirmed ? 'Deseja realizar esta operação?' : 'Antes de confirmar leia as orientações de inscrição.'
      let text = this.confirmed ? 'Após sua confirmação não será possível alterar.' : ''
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        title: msg_title,
        text: text,
        icon: 'question',
      }).then((r) => { 
        if(r.isConfirmed && this.confirmed){
          let enrolled = this.prepareEnrollmentStatus(this.course, status)
          this.updateEnrollment(enrolled)
        }else{
          this.dialog = true
          this.confirmed = true
        }
      })
    },
    updateEnrollment(enrolled){
      Api.Enrolled.update(enrolled).then(()=>{
        this.addNotification({type: 'success', msg: 'Realizado com sucesso'})
      }).catch(err => {
        this.addNotification({type: 'error', msg: `Erro: ${err}`})
      }).finally(()=>{
        this.$router.go()
      })
    },
    prepareEnrollmentStatus(course, new_status){
      let enrolled = {
        id: course.enrolled_id,
        status: new_status
      }
      return enrolled
    },
    ...mapActions({
      addNotification: 'Notification/add'
    })
  },
  computed: {
    responsivity_vh(){
      return this.$vuetify.breakpoint.lg ? 
      'max-height: 60vh ; overflow-y: auto' : 
      this.$vuetify.breakpoint.smAndDown ? 
      'max-height: 64vh ; overflow-y: auto' : 
      'max-height: 70vh ; overflow-y: auto'
    },
    ...mapState({
      user: state=> state.User.user
    })
  },
}
</script>

<style scoped>

>>> .theme--light.v-sheet--outlined {
    border: thin solid rgba(0, 0, 0, 0);
}

</style>